import classNames from 'classnames';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { useCallback, useEffect } from 'react';

import { SocialActionPlacements } from 'common/constants/trackingEventsNames';
import usePrevious from 'common/shared/hooks/usePrevious';
import trans from 'common/tools/translations/trans';

import {
  addTheater,
  getUserTheaters,
  removeTheater
} from 'website/actions/TheaterActions';
import onlyIfHasSocial from 'website/components/HoC/onlyIfHasSocial';
import AuthenticatedLink from 'website/components/user/AuthenticatedLink';
import { AsyncAction, Dispatch } from 'website/reducers';
import { UserState } from 'website/reducers/user';
import { EntityTypename, Theater } from 'website/types';

type FavoriteTheaterButtonProps = {
  classes?: string;
  dispatch: Dispatch;
  iconOnly?: boolean;
  theaterId?: Maybe<string> | undefined;
  theaterName?: Maybe<string> | undefined;
  trackingContext?: SocialActionPlacements;
  user: UserState;
};

const FavoriteTheaterButton = ({
  classes = '',
  dispatch,
  iconOnly = false,
  theaterId,
  theaterName,
  trackingContext = 'standalone',
  user
}: FavoriteTheaterButtonProps) => {
  const previousUser = usePrevious(user);
  const dispatchUserTheaters = useCallback(
    () => dispatch(getUserTheaters()),
    [dispatch]
  );

  useEffect(() => {
    if (!previousUser?.loggedIn && user.loggedIn) {
      dispatchUserTheaters();
    }
  }, [dispatchUserTheaters, previousUser?.loggedIn, user]);

  const theaterIsFavorite = () =>
    theaterId && user.theaters !== null && !!user.theaters?.[theaterId];

  const toggleFavorite = () => {
    let action: AsyncAction | null | undefined;

    const entity: Theater = {
      id: theaterId ?? '',
      internalId: theaterId,
      legacyId: parseInt(theaterId ?? '0'),
      name: theaterName,
      typename: EntityTypename.Theater
    };

    if (theaterIsFavorite()) {
      const relationId = theaterId && user.theaters?.[theaterId].data?.id;

      action = removeTheater(
        {
          relationId:
            typeof relationId === 'string'
              ? parseInt(relationId)
              : relationId ?? undefined,
          theaterId: theaterId ?? undefined
        },
        {
          entity: entity,
          socialActionPlacement: trackingContext
        }
      );
    } else {
      action = theaterId
        ? addTheater(theaterId, {
            entity: entity,
            socialActionPlacement: trackingContext
          })
        : null;
    }

    return action && dispatch(action);
  };

  const buttonClass = classNames(
    classes,
    'button button-add-theater icon icon-left icon-heart-full',
    { active: theaterIsFavorite() },
    {
      'button-add-theater-small button-icon tooltip-parent': iconOnly
    }
  );

  const buttonText = theaterIsFavorite()
    ? trans('theaters.added-to-favorites')
    : trans('theaters.add-to-favorites');
  return (
    <AuthenticatedLink className={buttonClass} onClick={toggleFavorite}>
      <span className={iconOnly ? 'tooltip tooltip-dark tooltip-child' : 'txt'}>
        {buttonText}
      </span>
    </AuthenticatedLink>
  );
};

export { FavoriteTheaterButton as FavoriteTheaterButtonForTest };
export default onlyIfHasSocial(FavoriteTheaterButton);
