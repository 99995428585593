import roller from 'common/module/ui/roller';
import { runAfterDesobfuscation } from 'common/tools/seo/desobfuscation';

import applicationBootstrap from 'website/bootstrap';
import theaterpageComingSoon from 'website/containers/showtimes/theaterpageComingSoon';
import theaterpageOvw from 'website/containers/showtimes/theaterpageOvw';
import theaterpagePremiere from 'website/containers/showtimes/theaterpagePremiere';
// theaters / showtimes UI
import theaterButtons from 'website/containers/user/theaterButtons';
import loadAnchorPlus from 'website/module/anchor/anchor-plus';
import emergenceBanner from 'website/module/emergence/banner-bottom';
import validateShowtimeTime from 'website/module/showtimes/showtimeSchedule';
import carousel from 'website/module/ui/carousel';
import setupTheaterMap from 'website/module/ui/theater-map';
import thirdNav from 'website/module/ui/third-nav';
import tooltip from 'website/module/ui/tooltip-theater-amenities';
import loadAccesLibre from 'website/third_party/acces-libre';

// bootstrap the application / store
applicationBootstrap().then(() => {
  // initialize individual theaters button only if the page does not contain a showtime list
  theaterButtons();

  setupTheaterMap();

  carousel();

  roller();

  thirdNav();

  emergenceBanner();

  validateShowtimeTime();

  tooltip();

  loadAnchorPlus();

  runAfterDesobfuscation(() => {
    theaterpageOvw();

    theaterpageComingSoon();

    theaterpagePremiere();

    loadAccesLibre();
  });
});
