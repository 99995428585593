import React, { Fragment } from 'react';
import { createRoot } from 'react-dom/client';

import { Maybe } from 'types/graphql-api.generated';

import { TheaterReservationTracking } from 'common/constants/trackingEventsNames';
import { readBooleanAttribute } from 'common/tools/dom/readAttribute';

import MovieCard from 'website/components/MovieCard';
import Pager from 'website/components/Pager';
import RollerDate from 'website/components/RollerDate/';
import PageFilters from 'website/components/showtimes/PageFilters';
import ShowtimesAlertMsg from 'website/components/showtimes/ShowtimesAlertMsg';
import ShowtimesTitle from 'website/components/showtimes/ShowtimesTitle';
import ShowtimesVersionHolder from 'website/components/showtimes/ShowtimesVersionHolder';
import {
  OnDateChange,
  RequestTheaterOverviewData,
  TheaterFromAttributes
} from 'website/containers/showtimes/types';
import { initializeCommonData } from 'website/containers/showtimes/utils/initializationUtils';
import {
  RequestPayload,
  ShowtimesFiltersFacets
} from 'website/containers/showtimes/utils/types';
import { openShowtimeModal } from 'website/services/modal';

import { getDisabledDates, movieForTracking, formatForApi } from './utils';
import {
  useDateChange,
  useFetchShowtimes,
  useFilterChange,
  usePageChange
} from './utils/useShowtimesHooks';

type MoviesShowtimeUiProps = {
  enableKidsLabelInMovieCard: boolean;
  enablePersonsInMovieCard: boolean;
  enableRatingInMovieCard?: boolean;
  moviesShowtimesData?: RequestTheaterOverviewData;
  onDateChange: OnDateChange;
  theater?: TheaterFromAttributes;
  theaterHasBooking?: boolean;
};

type ShowtimesUiProps = {
  anchor: HTMLElement;
  currentDay?: string;
  currentPageNumber: number;
  enableKidsLabelInMovieCard: boolean;
  enablePersonsInMovieCard: boolean;
  enableRatingInMovieCard?: boolean;
  filters?: Maybe<ShowtimesFiltersFacets[]>;
  rollerDates: string[];
  showtimesDates: string[];
  theater: TheaterFromAttributes;
  theaterHasBooking: boolean;
};

/* GLOBAL VARS */
let anchor: HTMLElement | null;
const SHOWTIME_API_ROUTE = 'internal_api_showtimes_theater_filtered';
const SHOWTIMES_LOCAL_ROUTE = 'theater_showtimes';

/* UI INTERMEDIATE COMPONENTS */
const MoviesShowtimeUi = ({
  enableKidsLabelInMovieCard,
  enablePersonsInMovieCard,
  enableRatingInMovieCard,
  moviesShowtimesData,
  onDateChange,
  theater,
  theaterHasBooking = false
}: MoviesShowtimeUiProps) => {
  if (
    (moviesShowtimesData?.error && moviesShowtimesData.message) ||
    !moviesShowtimesData?.results?.length
  ) {
    return (
      <ShowtimesAlertMsg
        data={moviesShowtimesData}
        onDateChange={onDateChange}
      />
    );
  }

  return (
    <div className="showtimes-list-holder">
      {moviesShowtimesData.results.map(item => {
        if (!item?.movie) {
          return null;
        }
        const { movie, showtimes } = item;

        const trackingObj: Omit<TheaterReservationTracking, 'showtime'> = {
          movie: movieForTracking(movie),
          theater
        };

        return (
          <MovieCard
            movie={movie}
            key={movie.internalId}
            enablePersonsInMovieCard={enablePersonsInMovieCard}
            enableKidsLabelInMovieCard={enableKidsLabelInMovieCard}
            enableRatingInMovieCard={enableRatingInMovieCard}
          >
            <div className="showtimes-anchor">
              <ShowtimesVersionHolder
                openModal={(showtimeId, fallback, trackingContext) =>
                  openShowtimeModal({
                    fallback,
                    showtimeId,
                    trackingContext
                  })
                }
                showtimes={showtimes}
                theaterHasBooking={theaterHasBooking}
                tracking={trackingObj}
              />
            </div>
          </MovieCard>
        );
      })}
    </div>
  );
};

/* UI FINAL RENDER COMPONENT */
const ShowtimesUi = ({
  anchor,
  currentDay,
  currentPageNumber,
  enableKidsLabelInMovieCard,
  enablePersonsInMovieCard,
  enableRatingInMovieCard,
  filters: filtersFromProps,
  rollerDates,
  showtimesDates,
  theater,
  theaterHasBooking
}: ShowtimesUiProps) => {
  const { data, payload, routeParam, setPayload, setRouteParam, totalPages } =
    useFetchShowtimes<RequestTheaterOverviewData, RequestPayload>(
      anchor,
      { filters: formatForApi(filtersFromProps) },
      {
        idTheater: theater?.internalId,
        day: currentDay,
        page: currentPageNumber
      },
      SHOWTIME_API_ROUTE
    );
  const { filters, handleFilterChange } = useFilterChange(
    data,
    filtersFromProps,
    setPayload,
    setRouteParam
  );
  const { handlePageChange, handlePagerUrl, selectedPage } = usePageChange(
    anchor,
    currentPageNumber,
    payload,
    SHOWTIMES_LOCAL_ROUTE,
    routeParam,
    setRouteParam
  );
  const { handleAlertDateChange, handleDateChange, selectedDate } =
    useDateChange(currentDay, rollerDates, setRouteParam);

  // INTERMEDIATE COMPONENT RENDER
  const renderShowtimeTitle = () => <ShowtimesTitle data={data} />;

  const renderFilters = () => {
    return (
      <PageFilters
        filters={filters}
        onFilterChange={handleFilterChange}
        tracking={{ theater, filters }}
        versionDisabled={false}
      />
    );
  };

  const renderRoller = () => {
    const disabledDates = getDisabledDates(rollerDates, showtimesDates);
    return (
      <RollerDate
        dates={rollerDates}
        disabledDates={disabledDates}
        selectedDate={selectedDate}
        onClick={index => handleDateChange(rollerDates[index])}
        tracking={{ theater }}
      />
    );
  };

  const renderMovies = () => {
    return (
      <MoviesShowtimeUi
        moviesShowtimesData={data}
        theaterHasBooking={theaterHasBooking}
        onDateChange={handleAlertDateChange.bind(this)}
        theater={theater}
        enablePersonsInMovieCard={enablePersonsInMovieCard}
        enableKidsLabelInMovieCard={enableKidsLabelInMovieCard}
        enableRatingInMovieCard={enableRatingInMovieCard}
      />
    );
  };

  const renderPager = () => {
    return (
      <Pager
        currentPage={selectedPage}
        pageCount={totalPages}
        includesFirst
        includesLast
        getPageUrl={handlePagerUrl.bind(this)}
        onNavigationChange={handlePageChange.bind(this)}
      />
    );
  };

  // FINAL UI COMPONENT RENDER

  return (
    <Fragment>
      {renderFilters()}
      {renderRoller()}
      {renderShowtimeTitle()}
      {renderMovies()}
      {renderPager()}
    </Fragment>
  );
};

export { ShowtimesUi as ShowtimesUiForTest };

/* EXECUTION */
export default async function init() {
  anchor = document.getElementById('theaterpage-showtimes-index-ui');

  if (!anchor) {
    return;
  }

  const {
    currentDay,
    currentPageNumber,
    filters,
    rollerDates,
    showtimesDates,
    theater
  } = await initializeCommonData(anchor);

  if (!theater) {
    return;
  }

  const enablePersonsInMovieCard = readBooleanAttribute(
    anchor,
    'data-enable-persons-movie-card',
    true
  );

  const enableKidsLabelInMovieCard = readBooleanAttribute(
    anchor,
    'data-brand-has-kids-universe',
    true
  );

  const enableRatingInMovieCard = readBooleanAttribute(
    anchor,
    'data-show-rating'
  );

  const root = createRoot(anchor);
  root.render(
    <ShowtimesUi
      anchor={anchor}
      currentDay={currentDay}
      currentPageNumber={currentPageNumber}
      enableKidsLabelInMovieCard={enableKidsLabelInMovieCard}
      enablePersonsInMovieCard={enablePersonsInMovieCard}
      enableRatingInMovieCard={enableRatingInMovieCard}
      filters={filters}
      rollerDates={rollerDates}
      showtimesDates={showtimesDates}
      theaterHasBooking={theater.hasBooking}
      theater={theater}
    />
  );
}
