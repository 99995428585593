import classNames from 'classnames';
import React from 'react';
import { createRoot } from 'react-dom/client';

import ShowtimesHour from 'common/components/Showtimes/ShowtimesHour';
import { dateProcessingFromAString } from 'common/tools/date/showtime';
import readAttribute, {
  readNumberAttribute
} from 'common/tools/dom/readAttribute';
import { isString } from 'common/types';

import { openShowtimeModal } from 'website/services/modal';

const standalone = (container: Element) => {
  const onDayAnchors = container.getElementsByClassName('showtimes-hour-block');
  for (const oneDayAnchor of onDayAnchors) {
    const itemAnchor = oneDayAnchor.getElementsByClassName(
      'showtimes-hour-item'
    )[0];

    if (!itemAnchor) {
      return;
    }
    const showtimeId = readNumberAttribute(itemAnchor, 'data-showtime-id');
    if (!showtimeId) {
      return;
    }
    const startsAt = readAttribute<string, string>(
      itemAnchor,
      'data-showtime-time',
      ''
    );
    const ticketingUrl = itemAnchor.getAttribute('href');
    const experiences = readAttribute<Array<string>, undefined>(
      itemAnchor,
      'data-experiences'
    );

    const startsAtData = dateProcessingFromAString(startsAt);
    const isPast = startsAtData.isPast;
    const className = classNames(
      { past: isPast },
      { bookable: ticketingUrl && !isPast }
    );

    const root = createRoot(oneDayAnchor);
    root.render(
      <ShowtimesHour
        className={className}
        experiences={!isString(experiences) ? experiences : undefined}
        hour={startsAtData.hour}
        openModal={(fallback, trackingContext) =>
          openShowtimeModal({
            fallback,
            showtimeId,
            trackingContext
          })
        }
        ticketingUrl={ticketingUrl}
        tracking={{}}
      />
    );
  }
};

export default standalone;
