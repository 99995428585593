import React, { Fragment } from 'react';

import { ShowtimeFilterButtonTracking } from 'common/constants/trackingEventsNames';

import {
  FilterItem,
  OnFilterChange
} from 'website/components/showtimes/PageFilters';

import FilterButton from './FilterButton';

type FilterSelectProps = {
  list: FilterItem[];
  title?: string;
  onFilterChange: OnFilterChange;
  tracking: Omit<ShowtimeFilterButtonTracking, 'filterName'>;
};
const FilterSelect = ({
  list,
  title,
  onFilterChange,
  tracking
}: FilterSelectProps) => {
  const titleElement = title?.length ? (
    <span className="showtime-filter-title">{title}</span>
  ) : null;

  // the list is an array of objects :
  // {
  //    active: false, => if this filter is active
  //    extraClasses: '', => extra classNames
  //    label: "", => the label to display
  //    onClick: () => {}, => the callback function at onclick user action
  //    tracking: {}, => the tracking data
  // }
  const listElements = list.map(item => {
    return (
      <FilterButton
        extraClasses="showtime-filter-option"
        key={item.key}
        onClick={onFilterChange.bind(null, item.originalKey, item.key)}
        tracking={{
          ...tracking
        }}
        {...item}
      />
    );
  });

  return (
    <Fragment>
      {titleElement}
      <div className="showtime-filter-select">{listElements}</div>
    </Fragment>
  );
};

export default FilterSelect;
