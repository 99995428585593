import React from 'react';

import { MQ_MEDIUM, MQ_SMALL } from 'common/constants/MediaQueries';
import {
  BOOKING,
  LOYALTY_CARDS,
  SHOWTIMES_EXPERIENCE,
  SHOWTIMES_PROJECTION
} from 'common/constants/Showtimes';
import { ShowtimeFilterButtonTracking } from 'common/constants/trackingEventsNames';
import trans from 'common/tools/translations/trans';

import FilterDropdown from 'website/components/showtimes/FilterDropdown';
import {
  FilterItem,
  FilterRewritten,
  FiltersRewritten,
  OnFilterChange
} from 'website/components/showtimes/PageFilters';

import FilterSelect from './FilterSelect';

type FilterDropdownSeeMoreProps = {
  filters: FiltersRewritten;
  mqState: number;
  onFilterChange: OnFilterChange;
  tracking: ShowtimeFilterButtonTracking;
};
const FilterDropdownSeeMore = ({
  filters,
  mqState,
  onFilterChange,
  tracking
}: FilterDropdownSeeMoreProps) => {
  const computeExtraProps = (items: FilterRewritten[]) => {
    let activeItemCount = 0;
    let firstItemLabel: string | undefined;
    let noItems = true;

    items.forEach(filterModel => {
      const hasValues = filterModel.values.length;
      if (noItems && hasValues) {
        noItems = false;
      }

      filterModel.values.forEach(filterValueModel => {
        if (filterValueModel.active) {
          activeItemCount += 1;

          if (!firstItemLabel) {
            firstItemLabel = filterValueModel.label;
          }
        }
      });
    });

    return { activeItemCount, firstItemLabel, noItems };
  };

  // list of data models with props for component <FilterSelect />
  const items: FilterRewritten[] = [];

  if (mqState <= MQ_SMALL) {
    // add to the "see more" dropdown the Format section if some results
    if (
      filters[SHOWTIMES_PROJECTION] &&
      filters[SHOWTIMES_PROJECTION].values.length
    ) {
      items.push(filters[SHOWTIMES_PROJECTION]);
    }

    // add to the "see more" dropdown the Experiences section if some results
    if (
      filters[SHOWTIMES_EXPERIENCE] &&
      filters[SHOWTIMES_EXPERIENCE].values.length
    ) {
      items.push(filters[SHOWTIMES_EXPERIENCE]);
    }
  }

  // booking is available but not display
  // add to 'more filters' dropdown menu
  if (
    filters[BOOKING] &&
    filters[BOOKING].values.length &&
    mqState !== MQ_MEDIUM
  ) {
    items.push({ ...filters[BOOKING], label: '' });
  }

  // add to the "see more" dropdown the Accepted cards section if some results
  if (filters[LOYALTY_CARDS] && filters[LOYALTY_CARDS].values.length) {
    items.push(filters[LOYALTY_CARDS]);
  }

  return (
    <FilterDropdown
      computeExtraProps={computeExtraProps.bind(this, items)}
      items={items}
      nullLabel={trans('showtimes.filters.more_filters')}
      itemLength={
        items.reduce<Array<FilterItem>>(
          (acc, item) => [...acc, ...item.values],
          []
        ).length ?? 0
      }
    >
      {filterModels =>
        filterModels?.map(filterModel => {
          return (
            <FilterSelect
              key={filterModel.label}
              list={filterModel.values}
              onFilterChange={onFilterChange}
              title={filterModel.label}
              tracking={tracking}
            />
          );
        })
      }
    </FilterDropdown>
  );
};

export default FilterDropdownSeeMore;
