import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {
  useState,
  useEffect,
  useCallback,
  useRef,
  Dispatch,
  SetStateAction
} from 'react';

import { Maybe } from 'types/graphql-api.generated';

import { addClass, removeClass } from 'common/tools/dom/classes';
import scrollTo from 'common/tools/dom/scrollTo';

import { OnDateChange } from 'website/containers/showtimes/types';
import {
  addToHistory,
  formatForApi,
  handlePagerHref,
  handleShowtimeFilterChange,
  requestShowtimes,
  updateOriginalFilters
} from 'website/containers/showtimes/utils';
import { LOADING_CLASS } from 'website/containers/showtimes/utils/constants';
import {
  Filter,
  RequestPayload,
  RequestShowtimesData,
  RouteParam,
  ShowtimesFiltersFacets
} from 'website/containers/showtimes/utils/types';

export const useDateChange = <R extends RouteParam>(
  currentDay?: string,
  rollerDates?: Maybe<string[]>,
  setRouteParam?: Dispatch<SetStateAction<R & Pick<RouteParam, 'day' | 'page'>>>
) => {
  const [selectedDate, setSelectedDate] = useState(currentDay);

  const handleDateChange = useCallback(
    (date?: Maybe<string>) => {
      if (date) {
        setSelectedDate(date);
        setRouteParam?.(routeParam => ({ ...routeParam, day: date, page: 1 }));
      }
    },
    [setRouteParam]
  );

  const handleAlertDateChange: OnDateChange = useCallback(
    (date, event) => {
      event.preventDefault();
      if (date) {
        handleDateChange(date);
      }
    },
    [handleDateChange]
  );

  return {
    handleAlertDateChange,
    handleDateChange,
    selectedDate
  };
};

export const usePageChange = <T extends RouteParam>(
  anchor: HTMLElement | null,
  currentPageNumber: number,
  payload: RequestPayload,
  routeName: string,
  routeParam: T,
  setRouteParam?: Dispatch<SetStateAction<T>>
) => {
  const [selectedPage, setSelectedPage] = useState(currentPageNumber);

  const handlePageChange = useCallback(
    (number: number, event?: MouseEvent) => {
      event?.preventDefault();
      setSelectedPage(number);
      setRouteParam?.(routeParam => ({ ...routeParam, page: number }));
      scrollTo(anchor, 500, true, false);
    },
    [anchor, setRouteParam]
  );

  useEffect(() => {
    if (routeParam.page && routeParam.page !== selectedPage) {
      setSelectedPage(routeParam.page);
    }
  }, [routeParam, selectedPage]);

  const handlePagerUrl = useCallback(
    (number: number) => handlePagerHref(routeName, number, payload, routeParam),
    [payload, routeName, routeParam]
  );

  return {
    handlePageChange,
    handlePagerUrl,
    selectedPage
  };
};

export const useFilterChange = <P extends RequestPayload, R extends RouteParam>(
  data?: RequestShowtimesData,
  filtersProps?: Maybe<ShowtimesFiltersFacets[]>,
  setPayload?: Dispatch<SetStateAction<P>>,
  setRouteParam?: Dispatch<SetStateAction<R>>
) => {
  const [filters, setFilters] = useState<Filter[] | null | undefined>(
    filtersProps
  );
  const currentFilters = useRef<Filter[] | null | undefined>(filtersProps);

  const handleFilterChange = (
    entityGroup: string,
    entityKey?: string | null
  ) => {
    const finalFilters = handleShowtimeFilterChange(
      filters,
      entityGroup,
      entityKey
    );
    if (!isEqual(currentFilters.current, finalFilters.filters)) {
      currentFilters.current = finalFilters.filters;
      setFilters(cloneDeep(currentFilters.current));
      setPayload?.(prevState => ({
        ...prevState,
        filters: formatForApi(filters)
      }));
      setRouteParam?.(routeParam => ({ ...routeParam, page: 1 }));
    }
  };

  useEffect(() => {
    const updatedFilters = updateOriginalFilters(filters, data?.facets.facets);
    if (!isEqual(currentFilters.current, updatedFilters)) {
      currentFilters.current = updatedFilters;
      setFilters(cloneDeep(currentFilters.current));
    }
  }, [data, filters]);

  return {
    filters,
    handleFilterChange
  };
};

// Type guards
const isRouteParam = (param: any): param is RouteParam =>
  param && (typeof param.day === 'string' || typeof param.page === 'number');

const isRequestPayload = (payload: any): payload is RequestPayload =>
  payload && typeof payload.filters === 'object';

export const useFetchShowtimes = <
  T extends RequestShowtimesData,
  P extends RequestPayload,
  R = RouteParam
>(
  anchor: Element,
  defaultPayload: P,
  defaultRouteParam: R,
  route: string,
  validatingQuery?: (routeParam: R, payload: P) => boolean
) => {
  const [fetchData, setFetchData] = useState<T>();
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState<P>(defaultPayload);
  const [routeParam, setRouteParam] = useState<R>(defaultRouteParam);

  const [totalPages, setTotalPages] = useState(1);
  const currentPayload = useRef<P>();
  const currentRouteParam = useRef<R>();

  const handleRequest = useCallback(async () => {
    if (validatingQuery && !validatingQuery(routeParam, payload)) {
      return;
    }
    try {
      setLoading(true);

      const data = await requestShowtimes<T, P, R>(route, routeParam, payload);

      if (isRouteParam(routeParam) && isRequestPayload(payload)) {
        addToHistory(routeParam, payload);
      }
      setFetchData(data);
      setTotalPages(data?.pagination?.totalPages ?? 1);
    } finally {
      setLoading(false);
    }
  }, [payload, route, routeParam, validatingQuery]);

  useEffect(() => {
    if (
      !isEqual(currentRouteParam.current, routeParam) ||
      !isEqual(currentPayload.current, payload)
    ) {
      currentRouteParam.current = routeParam;
      currentPayload.current = payload;
      handleRequest();
    }
  }, [handleRequest, payload, routeParam]);

  // REACT LIFE CYCLE
  useEffect(() => {
    if (loading) {
      addClass(anchor, LOADING_CLASS);
    } else {
      removeClass(anchor, LOADING_CLASS);
    }
  }, [anchor, loading]);

  return {
    data: fetchData,
    loading,
    payload,
    routeParam,
    setPayload,
    setRouteParam,
    totalPages
  };
};
